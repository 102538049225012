import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import GoogleMaps from "../googleMaps/GoogelMaps.jsx";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import SendIcon from "@material-ui/icons/Send";
import TextFieldWithPattern from "../textFieldWithPattern/TextFieldWithPattern.jsx";
import "./ContactMe.css";
import { withSnackbar } from "notistack";

const firebase = require("firebase");
require("firebase/app");

// Required for side-effects

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // "& > *": {
    //   margin: theme.spacing(1),
    //   width: "25ch",
    // },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const BasicTextFields = ({ enqueueSnackbar }) => {
  var firestoreEmailFunc = firebase.functions().httpsCallable("firestoreEmail");

  const classes = useStyles();
  const [name, setName] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [userEmail, setUserEmail] = React.useState("");
  const [telefonNumber, setTelefonNumber] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [canPressCreateAccount, setCanPressCreateAccount] = React.useState(
    false
  );
  const [reset, setReset] = React.useState(false);
  const [errorTelefonNumber, setEfforTelefonNumber] = React.useState(false);

  const changeStateName = (value) => {
    setName(value);
  };

  const changeStateMessage = (value) => {
    setMessage(value);
  };
  const changeStateEmail = (value) => {
    setUserEmail(value);
  };

  const changeStateSubject = (value) => {
    setSubject(value);
  };
  const changeStateTelefon = (value) => {
    setTelefonNumber(value);
    setEfforTelefonNumber(true);
    const regex = RegExp(/[^0-9]/g);

    let regexTest = regex.test(telefonNumber);
    if (regexTest === false && value > 1) {
      setEfforTelefonNumber(false);
    }
  };

  const checkStateTelefon = (value) => {
    const onlyNums = value.replace(/[^0-9]/g, "");
    console.log(value);
    setEfforTelefonNumber(false);
    // Check if the telefone number exceeds 8
    if (onlyNums.length !== 8 && onlyNums.length !== 0) {
      setEfforTelefonNumber(true);
    }
    setTelefonNumber(onlyNums);
  };

  const sendEmail = () => {
    openApiKeySuccesSnackBar();
    setName("");
    setMessage("");
    setSubject("");
    setTelefonNumber("");
    setReset(true);

    firestoreEmailFunc({
      email: "janhoveling@gmail.com",
      subject: subject,
      name: name,
      userEmail: userEmail,
      telefonNumber: telefonNumber,
      message: message,
    })
      .then(() => console.log("email sent!"))
      .catch((err) => console.log(err));
  };

  const checkIfCanPressCreateAccount = (value) => {
    console.log("Setting state to ", value);
    setCanPressCreateAccount(value);
  };

  const openApiKeySuccesSnackBar = () => {
    enqueueSnackbar("Din email blev sendt!", {
      variant: "success",
      autoHideDuration: 2000,
    });
  };

  return (
    <div className="wrapper">
      <div className="title">Kontakt mig</div>
      <div className="subTitle">
        Du er velkommen til at kontakte mig via. nedenstående form eller via.
        min email{" "}
      </div>
      <br></br>
      <div className="content">
        <GoogleMaps></GoogleMaps>
      </div>
      <br></br>
      <br></br>
      <div className="Pre">
        <Grid container spacing={4}>
          <Grid item xs>
            <TextField
              autoFocus
              fullWidth
              required
              value={name}
              label="Dit navn"
              id="name"
              variant="outlined"
              size="small"
              onChange={(e) => changeStateName(e.target.value)}
            />
          </Grid>
          <Grid item xs={8}>
            <TextFieldWithPattern
              pattern={/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/}
              required
              fullWidth
              reset={reset}
              setReset={setReset}
              id="email"
              size="small"
              variant="outlined"
              label="Din email"
              name="email"
              autoComplete="new-password"
              // onChange={(e) => changeStateEmail(e.target.value)}
              onBlur={(e) => changeStateEmail(e.target.value)}
              checkifcanpresscreateaccount={checkIfCanPressCreateAccount}
            />
          </Grid>
        </Grid>
        <br></br>
        <Grid container spacing={4}>
          <Grid item xs={8}>
            <TextField
              fullWidth
              value={subject}
              label="Emne"
              required
              id="message"
              variant="outlined"
              size="small"
              onChange={(e) => changeStateSubject(e.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              value={telefonNumber}
              label="Telefonnummer"
              id="message"
              error={errorTelefonNumber}
              variant="outlined"
              size="small"
              helperText={
                errorTelefonNumber ? "fx. 12345678 (kun tal, minimum 8)" : ""
              }
              onChange={(e) => changeStateTelefon(e.target.value)}
              onBlur={(e) => checkStateTelefon(e.target.value)}
            />
          </Grid>
        </Grid>
        <br></br>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              value={message}
              label="Besked"
              id="message"
              variant="outlined"
              helperText="Hvad har du på hjertet?"
              multiline
              rows={3}
              colms={12}
              onChange={(e) => changeStateMessage(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={5}></Grid>
          <Grid item xs={5}></Grid>
          <Grid item xs={2}>
            <Button
              variant="outlined"
              required
              onClick={sendEmail}
              size="small"
              style={{ width: "100%" }}
              endIcon={<SendIcon>Send</SendIcon>}
              disabled={
                message === null ||
                name === (null || "") ||
                subject === (null || "") ||
                canPressCreateAccount === false ||
                errorTelefonNumber === true ||
                telefonNumber === (telefonNumber.length !== 8)
              }
            >
              Send
            </Button>
          </Grid>
        </Grid>
      </div>
      <div className="Post">
        <Grid container spacing={4}>
          <Grid item xs>
            <TextField
              autoFocus
              fullWidth
              required
              value={name}
              label="Dit navn"
              id="name"
              variant="outlined"
              size="small"
              onChange={(e) => changeStateName(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs>
            <TextFieldWithPattern
              pattern={/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/}
              required
              fullWidth
              reset={reset}
              setReset={setReset}
              id="email"
              size="small"
              variant="outlined"
              label="Din email"
              name="email"
              autoComplete="new-password"
              // onChange={(e) => changeStateEmail(e.target.value)}
              onBlur={(e) => changeStateEmail(e.target.value)}
              checkifcanpresscreateaccount={checkIfCanPressCreateAccount}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs>
            <TextField
              fullWidth
              value={subject}
              label="Emne"
              required
              id="message"
              variant="outlined"
              size="small"
              onChange={(e) => changeStateSubject(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs>
          <TextField
              fullWidth
              value={telefonNumber}
              label="Telefonnummer"
              id="message"
              error={errorTelefonNumber}
              variant="outlined"
              size="small"
              helperText={
                errorTelefonNumber ? "fx. 12345678 (kun tal, minimum 8)" : ""
              }
              onChange={(e) => changeStateTelefon(e.target.value)}
              onBlur={(e) => checkStateTelefon(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs>
          <TextField
              fullWidth
              required
              value={message}
              label="Besked"
              id="message"
              variant="outlined"
              helperText="Hvad har du på hjertet?"
              multiline
              rows={3}
              colms={12}
              onChange={(e) => changeStateMessage(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            <Button
              variant="outlined"
              required
              onClick={sendEmail}
              size="small"
              style={{ width: "100%" }}
              endIcon={<SendIcon>Send</SendIcon>}
              disabled={
                message === null ||
                name === (null || "") ||
                subject === (null || "") ||
                canPressCreateAccount === false ||
                errorTelefonNumber === true ||
                telefonNumber === (telefonNumber.length !== 8)
              }
            >
              Send
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default withSnackbar(BasicTextFields);
