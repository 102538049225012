import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MailIcon from "@material-ui/icons/Mail";

import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Avatar from "../../images/profilePic.png";
import "./SideBar.css";
import Chip from "@material-ui/core/Chip";
import RoomIcon from "@material-ui/icons/Room";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import Tooltip from "@material-ui/core/Tooltip";
import EventIcon from "@material-ui/icons/Event";
import Divider from "@material-ui/core/Divider";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: "auto",
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
  },
  paddingBetweenListItems: {
    paddingBottom: "2px",
    paddingTop: "2px",
  },
  listItemText: {
    fontSize: "0.8em", //Insert your required size
    fontFamily: "Inter",
    verticalAlign: "center",
  },
  listItemBttn: {
    minWidth: "0px",
    paddingRight: "10px",
  },
  listItemEtik: {
    minWidth: "0px",
    paddingRight: "10px",
  },
  listItemIcons: {
    fontsize: "0.8em",
  },
  textAtBottomDiv: {
    textAlign: "center",
    paddingLeft: "38px",
    paddingRight: "24px",
    fontSize: "13px",
    paddingBottom: "20px"
  },
  textAtBottom: {
    textAlign: "justify",
    textJustify: "inter-word",
  },
}));

export default function SimplePaper() {
  const classes = useStyles();
  const [dense, setDense] = React.useState(false);

  return (
    <div>
      <div className="outterDiv">
        <img src={Avatar} className="avatar" alt="Logo" />
        <div style={{ paddingTop: "10x", paddingBottom: "10px" }}>
          <br />
          <span className="tileSideBar">
            Jan <b>Hoveling</b>
          </span>
          <br />
        </div>
        <Chip
          avatar={<LinkedInIcon style={{ color: "steelblue" }}></LinkedInIcon>}
          size="small"
          href="https://www.linkedin.com/in/janhoveling/"
          label="link til LinkedIn"
          variant="outlined"
          component="a"
          clickable
        ></Chip>
        <div style={{ width: "80%", margin: "auto" }}>
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.title}></Typography>
            <div style={{ width: "100%" }}>
              <List dense={dense}>
                <Tooltip title="Fødselsdag" placement="right" arrow>
                  <ListItem className={classes.paddingBetweenListItems}>
                    <ListItemIcon className={classes.listItemBttn}>
                      <EventIcon style={{ fontSize: "1em" }}></EventIcon>
                    </ListItemIcon>

                    <ListItemText
                      classes={{ primary: classes.listItemText }}
                      primary="22/04, 1966"
                    />
                  </ListItem>
                </Tooltip>
                <Tooltip title="Bopæl" placement="right" arrow>
                  <ListItem className={classes.paddingBetweenListItems}>
                    <ListItemIcon className={classes.listItemBttn}>
                      <RoomIcon style={{ fontSize: "1em" }}></RoomIcon>
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.listItemText }}
                      primary="Skanderborg"
                    />
                  </ListItem>
                </Tooltip>
                <Tooltip title="Email" placement="right" arrow>
                  <ListItem className={classes.paddingBetweenListItems}>
                    <ListItemIcon className={classes.listItemBttn}>
                      <MailIcon style={{ fontSize: "1em" }}></MailIcon>
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.listItemText }}
                      primary="Janhoveling@gmail.com"
                    />
                  </ListItem>
                </Tooltip>
                <Tooltip title="Telefonnummer" placement="right" arrow>
                  <ListItem className={classes.paddingBetweenListItems}>
                    <ListItemIcon className={classes.listItemBttn}>
                      <PhoneIphoneIcon
                        style={{ fontSize: "1em" }}
                      ></PhoneIphoneIcon>
                    </ListItemIcon>
                    <ListItemText
                      classes={{
                        primary: classes.listItemText,
                        secondary: classes.listItemText,
                      }}
                      primary=" +(45) 91 34 51 40"
                      // secondary={"Secondary text"}
                    />
                  </ListItem>
                </Tooltip>
              </List>
            </div>
          </Grid>
        </div>
        <Divider variant="middle" style={{ marginBottom: "10px" }} />
        <div style={{ width: "80%", margin: "auto" }}>
          <ListItem className={classes.paddingBetweenListItems}>
            <ListItemIcon className={classes.listItemBttn}>
              <LocalLibraryIcon style={{ fontSize: "1em" }}></LocalLibraryIcon>
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: classes.listItemText,
                secondary: classes.listItemText,
              }}
              primary=" Etik"
              // secondary={"Secondary text"}
            />
          </ListItem>
        </div>
        <div className={classes.textAtBottomDiv}>
          {" "}
          <span className={classes.textAtBottom}>
            Jeg arbejder ud fra de etiske regler – opsat af Dansk Psykoterapeut
            Forening.
          </span>
        </div>
      </div>
      <div className="POSToutterDiv">
        <img src={Avatar} className="avatar" alt="Logo" />
        <div style={{ paddingTop: "10x", paddingBottom: "10px" }}>
          <br />
          <span className="tileSideBar">
            Jan <b>Hoveling</b>
          </span>
          <br />
        </div>
        <Chip
          avatar={<LinkedInIcon style={{ color: "steelblue" }}></LinkedInIcon>}
          size="small"
          href="https://www.linkedin.com/in/janhoveling/"
          label="link til LinkedIn"
          variant="outlined"
          component="a"
          clickable
        ></Chip>
        <div style={{ width: "80%", margin: "auto" }}>
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.title}></Typography>
            <div style={{ width: "100%" }}>
              <List dense={dense}>
                <Tooltip title="22/04, 1966" placement="right" arrow>
                  <ListItem className={classes.paddingBetweenListItems}>
                    <ListItemIcon className={classes.listItemBttn}>
                      <EventIcon style={{ fontSize: "1em" }}></EventIcon>
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.listItemText }}
                      primary="Fødselsdag"
                    />
                  </ListItem>
                </Tooltip>
                <Tooltip title="Skanderborg" placement="right" arrow>
                  <ListItem className={classes.paddingBetweenListItems}>
                    <ListItemIcon className={classes.listItemBttn}>
                      <RoomIcon style={{ fontSize: "1em" }}></RoomIcon>
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.listItemText }}
                      primary="Bopæl"
                    />
                  </ListItem>
                </Tooltip>
                <Tooltip title="Janhoveling@gmail.com" placement="right" arrow>
                  <ListItem className={classes.paddingBetweenListItems}>
                    <ListItemIcon className={classes.listItemBttn}>
                      <MailIcon style={{ fontSize: "1em" }}></MailIcon>
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.listItemText }}
                      primary="Email"
                    />
                  </ListItem>
                </Tooltip>
                <Tooltip title="+(45) 91 34 51 40" placement="right" arrow>
                  <ListItem className={classes.paddingBetweenListItems}>
                    <ListItemIcon className={classes.listItemBttn}>
                      <PhoneIphoneIcon
                        style={{ fontSize: "1em" }}
                      ></PhoneIphoneIcon>
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.listItemText }}
                      primary="Telefonnummer"
                    />
                  </ListItem>
                </Tooltip>
              </List>
            </div>
          </Grid>
        </div>
        <Divider variant="middle" style={{ marginBottom: "10px" }} />
        <div style={{ width: "30%", margin: "auto" }}>
          <Tooltip
            title="Jeg arbejder ud fra de etiske regler – opsat af Dansk Psykoterapeut
            Forening."
            placement="right"
            arrow
          >
            <ListItem className={classes.paddingBetweenListItems}>
              <ListItemIcon className={classes.listItemBttn}>
                <LocalLibraryIcon
                  style={{ fontSize: "1em" }}
                ></LocalLibraryIcon>
              </ListItemIcon>
            </ListItem>
          </Tooltip>
        </div>
      </div>
     
      <div className="PhoneoutterDiv">
      <Grid container >
          <Grid item xs={12}>
          <div style={{ paddingTop: "10x", paddingBottom: "10px" }}>
              <br />
              <span className="tileSideBar">
                Jan <b>Hoveling</b>
              </span>
              <br />
            </div>
            <Chip
              style={{marginBottom: "10px"}}
              avatar={
                <LinkedInIcon style={{ color: "steelblue" }}></LinkedInIcon>
              }
              size="small"
              href="https://www.linkedin.com/in/janhoveling/"
              label="link til LinkedIn"
              variant="outlined"
              component="a"
              clickable
            ></Chip>
          </Grid>
        </Grid>
        <Grid style={{ textAlign: "right" }} container spacing={1}>
          <Grid item xs={5} >
            <img src={Avatar} className="avatar" alt="Logo" />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" className={classes.title}></Typography>
            <div style={{ width: "100%" }}>
              <List dense={dense}>
                  <ListItem className={classes.paddingBetweenListItems}>
                    <ListItemIcon className={classes.listItemBttn}>
                      <EventIcon style={{ fontSize: "1em" }}></EventIcon>
                    </ListItemIcon>

                    <ListItemText
                      classes={{ primary: classes.listItemText }}
                      primary="22/04, 1966"
                    />
                  </ListItem>
                  <ListItem className={classes.paddingBetweenListItems}>
                    <ListItemIcon className={classes.listItemBttn}>
                      <RoomIcon style={{ fontSize: "1em" }}></RoomIcon>
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.listItemText }}
                      primary="Skanderborg"
                    />
                  </ListItem>
                  <ListItem className={classes.paddingBetweenListItems}>
                    <ListItemIcon className={classes.listItemBttn}>
                      <MailIcon style={{ fontSize: "1em" }}></MailIcon>
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.listItemText }}
                      primary="Janhoveling@gmail.com"
                    />
                  </ListItem>
                  <ListItem className={classes.paddingBetweenListItems}>
                    <ListItemIcon className={classes.listItemBttn}>
                      <PhoneIphoneIcon
                        style={{ fontSize: "1em" }}
                      ></PhoneIphoneIcon>
                    </ListItemIcon>
                    <ListItemText
                      classes={{
                        primary: classes.listItemText,
                        secondary: classes.listItemText,
                      }}
                      primary=" +(45) 91 34 51 40"
                      // secondary={"Secondary text"}
                    />
                  </ListItem>
              </List>
            </div>
          </Grid>
        </Grid>
        <Divider variant="middle" style={{ marginBottom: "10px" }} />
        
        <div style={{ width: "50%", margin: "auto" }}>
        <span style={{fontSize: "14px"}} >
        <LocalLibraryIcon style={{ fontSize: "1em",  }}></LocalLibraryIcon> Etik
          </span>
        </div>
        <div className={classes.textAtBottomDiv}>
          {" "}
          <span className={classes.textAtBottom}>
            Jeg arbejder ud fra de etiske regler – opsat af Dansk Psykoterapeut
            Forening.
          </span>
        </div>
           
        </div>

    </div>
  );
}
