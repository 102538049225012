import React from "react";
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";

const TextFieldWithPattern = ({ pattern, reset, setReset, checkifcanpresscreateaccount, ...props }) => {
  const [value, setValue] = React.useState("");
  const [error, setError] = React.useState(false);

  const handleChange = e => {
    const input = e.target.value;
    if (!pattern.test(input) && input !== "") {
      setError(true);
      checkifcanpresscreateaccount(false)
    } else {
      setError(false);
      checkifcanpresscreateaccount(true)
    }
    setValue(input);
   
  };

  if(reset && value !== ""){
    setValue("");
    setReset(false);
  }

  return <TextField helperText={error ? "Den givet email er invalid" : ""} error={error} onChange={handleChange} value={value} {...props} />;
};


TextFieldWithPattern.propTypes = {
  pattern: PropTypes.instanceOf(RegExp).isRequired,
  checkifcanpresscreateaccount: PropTypes.func,
  reset: PropTypes.bool,
  setReset: PropTypes.func
};

export default TextFieldWithPattern;