import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Tooltip from "@material-ui/core/Tooltip";

class SimpleMap extends Component {
  static defaultProps = {
    center: {
      lat: 56.08,
      lng: 10.13,
    },
    zoom: 12,
    marker: {
      lat: 56.085584,
      lng: 10.138072,
    },
  };
  Marker;

  renderMarkers(map, maps) {
  }

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: "210px", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyBaTO2U2rI5PG6X4NmPvmGtnzLbuakSAkQ" }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
          // onGoogleApiLoaded={({map, maps}) => this.renderMarkers(map, maps)}
        >
          <Marker lat={56.085584} lng={10.138072} />
        </GoogleMapReact>
      </div>
    );
  }
}
const Marker = (props) => {
  return (
   
      <div>
         <Tooltip title="Bopæl" placement="right" arrow>
        <LocationOnIcon
          style={{ color: "#b51111", fontSize: "300%" }}
        ></LocationOnIcon>
        </Tooltip>
      </div>

  );
};

export default SimpleMap;
