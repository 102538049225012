import React from "react";

import Grid from "@material-ui/core/Grid";

import "./Home.css";

export default function Content() {
  return (
    <div className="wrapper">
      <div className="title">”Coronatid og samtaler”</div>
      <div className="subTitle">
        Har du brug for forandring - Personligt eller professionelt{" "}
      </div>
      <br></br>
      <div className="content">
        Jeg tilbyder i, disse Coronatider, gratis terapi timer. Har du manglet
        tid, økonomi eller måske bare et godt incitament til at gøre noget godt
        for dig selv, så er det måske nu, du skal slå til. Jeg er uddannet
        Master Practitioner i Enneagram og NLP og er snart færdiguddannet som
        terapeut. Som et led i uddannelsen er der krav om et vist antal
        samtaler, og derfor har jeg valgt at kombinere ”Coronatid og samtaler”
        på denne måde.
        <br />
        <br />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            Jeg sørger for en tryg og behagelig atmosfære, hvor du får en
            oplevelse af nærvær og fortrolighed. Det kan være i et lokale eller
            det kan være udenfor – f.eks. som en ”walk & talk” – i alle tilfælde
            vil du føle et ”rum”, hvor det er dig, der er i fokus.
          </Grid>
          <Grid item xs={12} sm={6}>
            Igennem samtaler kan du få større bevidsthed og indsigt, se det hele
            i et nyt perspektiv og dermed give dig selv og andre nye muligheder
            for permanente forandringer
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            Du har mulighed for at dele og bearbejde de udfordringer, ønsker,
            valg eller oplevelser du måtte stå med i dit liv lige nu.
          </Grid>
          <Grid item xs={12} sm={6}>
            Hvis du er blevet inspireret til at rejse ind i et af de allermest
            spændende universer – nemlig dig selv – så kontakt mig på mail – så
            vender jeg hurtigt tilbage.
          </Grid>
        </Grid>
        <Grid style={{marginTop: "30px"}} container spacing={3}>
          <Grid item xs={12} sm={6}></Grid>
          <Grid item xs={12} sm={6}>
            Lad din opdagelsesrejse begynde...
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
