import React from "react";
import "./App.css";
import Header from "./components/header/Header.jsx";
import { SnackbarProvider } from "notistack";
import SidePanel from "./components/sideBar/SideBar.jsx";
import Home from "./components/home/Home.jsx";
import ContactMe from "./components/contactMe/ContactMe.jsx";
import SwipeableViews from "react-swipeable-views";
import Education from "./components/education/Education.jsx";
import "./components/background/particles.json";

import Footer from "./components/footer/Footer.jsx"
import firebase from 'firebase/app';

require("dotenv").config();

const firebaseConfig = {
  apiKey: "AIzaSyBaTO2U2rI5PG6X4NmPvmGtnzLbuakSAkQ",
  authDomain: "jhcv-c221c.firebaseapp.com",
  databaseURL: "https://jhcv-c221c.firebaseio.com",
  projectId: "jhcv-c221c",
  storageBucket: "jhcv-c221c.appspot.com",
  messagingSenderId: "1003753305403",
  appId: "1:1003753305403:web:11352e40b892477583b31e",
  measurementId: "G-RHRRJFRJS5",
};

firebase.initializeApp(firebaseConfig);

function App() {
  const [viewIndex, setViewIndex] = React.useState(false);

  return (
    <SnackbarProvider maxSnack={3}>
        <div className="App">
          <div className="mainContentWrapper">
            {/* <div className="filler"> </div> */}
            <div className="navDiv">
              {" "}
              <Header onChange={setViewIndex}></Header>
            </div>
            <div className="leftPanelPhone">
                {" "}
                <SidePanel></SidePanel>
              </div>
            <div className="wrapperOfDiv">
              <div className="leftPanel">
                {" "}
                <SidePanel></SidePanel>
              </div>
              <div className="mainFrame">
                <SwipeableViews style={{backgroundColor: "white"}} index={viewIndex} >
                  <Home style={Object.assign({})}></Home>
                  <Education style={Object.assign({})}></Education>
                  <ContactMe style={Object.assign({})}></ContactMe>
                </SwipeableViews>
              </div>
            </div>
          </div>
          <Footer></Footer>
        </div>

    </SnackbarProvider>
  );
}

export default App;
