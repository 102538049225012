import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import Chip from "@material-ui/core/Chip";
import BugReportIcon from "@material-ui/icons/BugReport";
import Divider from "@material-ui/core/Divider";
import "./Footer.css"
// Required for side-effects

const useStyles = makeStyles((theme) => ({
  footerStyling: {
    position: "fixed",
    bottom: "0",
    width: "100%",
    backgroundColor: "#f5f5f5",
    left: "0",
    fontFamily: "Inter",
  },
  innerFooterDiv: {
    height: "100%",
    width: "1200px",
    margin: "auto",
    fontSize: "10px",
    textAlign: "left",
    display: "flex",
  },
  Icons: {
    fontSize: "14px",
    verticalAlign: "sub",
    marginRight: "3px",
    color: "gray",
  },
  dividerStyling: {
    backgroundColor: "gray",
    //   margin: "8px",
    heigh: "18px",
    marginLeft: "10px",
    marginRight: "10px",
  },
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <div className="TabletAnDPhone">
      <div className={classes.footerStyling}>
        <div className={classes.innerFooterDiv}>
          <Chip
            size="small"
            style={{
              fontSize: "10px",
              marginTop: "12px",
              marginBottom: "12px",
              color: "Darkblue",
            }}
            label="Sidst opdateret d. 03/11/2020"
          />
          <Divider
            className={classes.dividerStyling}
            orientation="vertical"
            flexItem
          />

          <div style={{ fontSize: "10px", marginTop: "16px" }}>
            <BugReportIcon className={classes.Icons}></BugReportIcon>
            Problemer med hjemmesiden? Kontakt venligst 201508876PMH@gmail.com
          </div>
        </div>
      </div>
    </div>
  );
};

export default withSnackbar(Footer);
