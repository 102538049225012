import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import SchoolIcon from "@material-ui/icons/School";

import ListItemIcon from "@material-ui/core/ListItemIcon";
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import "./Education.css"
import Typography from '@material-ui/core/Typography';
import WavesIcon from '@material-ui/icons/Waves';
import SportsKabaddiIcon from '@material-ui/icons/SportsKabaddi';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  font:{
    fontFamily: "Inter",
  },
  secondaryFont:{
    color: '#3f51b5',
    fontSize: "13px",
    opacity: '0.8',
    fontFamily: "Inter"
    
  }
}));

export default function Content() {
  const classes = useStyles();

  return (
    <div className="wrapper">
      <div className="title">Udannelser:</div>
  
      <List dense={true}>
        <ListItem>
          <ListItemIcon>
            <SchoolIcon />
          </ListItemIcon>
          <ListItemText
        primary="Maskinmester"
        secondary={<Typography type="body" className={classes.secondaryFont} >1987 - 1992</Typography>}
      />
        
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <SchoolIcon />
          </ListItemIcon>
          <ListItemText primary="Graduate, Economics - Business, Virksomhedsøkonomi"  secondary={<Typography type="body" className={classes.secondaryFont} >1995 - 1996</Typography>} />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <SchoolIcon />
          </ListItemIcon>
          <ListItemText primary="MBA, General Managament, Master of Business Administration" secondary={<Typography type="body" className={classes.secondaryFont} >2001 - 2003</Typography>} />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <SchoolIcon />
          </ListItemIcon>
          <ListItemText primary="MSc, Finance, Master of Science - Finance" secondary={<Typography type="body" className={classes.secondaryFont} >2004 - 2006</Typography>} />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <SchoolIcon />
          </ListItemIcon>
          <ListItemText primary="Master Practitioner Enneagram og NLP"  secondary={<Typography type="body" className={classes.secondaryFont} >2016 - 2018</Typography>} /> 
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <WavesIcon />
          </ListItemIcon>
          <ListItemText primary="Certificeret dykkerinstruktør"secondary={<Typography type="body" className={classes.secondaryFont} >PADI (MSDT no. 605261)</Typography>} />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <SportsKabaddiIcon />
          </ListItemIcon>
          <ListItemText primary="Karate instruktør" secondary={<Typography type="body" className={classes.secondaryFont} > 9. Dan.</Typography>}  />
        </ListItem>
       
      </List>
      <br></br>
      <div className="title">Erhverserfaring:</div>
      <List dense={true}>
        <ListItem>
          <ListItemIcon>
            <BusinessCenterIcon />
          </ListItemIcon>
          <ListItemText primary="28 års erhvervserfaring som chef, i Danmark og udlandet." secondary="Sideløbende arbejdet som lærer og censor ved Akademiuddannelserne" />
        </ListItem>
      </List>
    </div>

    
  );
}

