import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import "./Header.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  Bttn: {
    marginLeft: "5px",
    marginRight: "5px",
    fontFamily: "Inter",
  },
}));

export default function TextButtons({ onChange }) {
  const classes = useStyles();

  return (
    <div className="nav">
      <Button
        className={classes.Bttn}
        onClick={() => onChange(0)}
        color="primary"
        variant="outlined"
      >
        Hjem
      </Button>
      <Button className={classes.Bttn} onClick={() => onChange(1)}>
        Uddannelser & erhverserfaring
      </Button>
      <Button className={classes.Bttn} onClick={() => onChange(2)}>
        Kontakt mig
      </Button>
    </div>
  );
}
